<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Type collaborateur" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail du type collaborateur</div>
              </v-row>
            </v-card-title>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field dense v-model="label"></v-text-field>
                </v-col>
              </v-row>              
              <v-divider></v-divider>
            </v-card-text>

            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">nom digital</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field 
                    dense 
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>    
              <v-divider></v-divider>          
            </v-card-text>

            <!-- le status humain / non humain -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="label-field capitalize">est un type humain</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="isPerson"></v-checkbox>
                </v-col>
              </v-row>              
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="mx-2 my-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >Enregistrer</v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <!-- Fenetre modale pour éviter que l'utilisateur quitte sans sauvegarder -->
    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import UsersTypeService from "@/service/user/user_type_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditDiseaseReason",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      userTypeService: null,

      /** l'identifiant à éditer */
      userTypeId: null,

      /**La donnée originale */
      source: null,

      /**le label */
      label: null,
      /**le nom digital */
      digitalName: null,
      /** est de type humain */
      isPerson: false,

    };
  },

  methods: {
    async load() {
      try {
        this.loading = true;

        let item = await this.userTypeService.getUserTypeById(this.userTypeId);
        // Mise en double de l'info pour avoir le bon nom de la variable.
        item.isPerson = item.person;

        this.source = JSON.parse(JSON.stringify(item));

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },

    init() {
      this.label = this.source.label;
      this.digitalName = this.source.digitalName;
      this.isPerson = this.source.isPerson;
    },

    async save() {
      try {
        let element = JSON.parse(JSON.stringify(this.source));

        element.label = this.label;
        element.digitalName = this.digitalName;
        element.person = this.isPerson;

        await this.userTypeService.update(element);

        this.source = JSON.parse(JSON.stringify(element));

        this.disableAlertQuit();
        this.$router.go(-1);
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "enregistrement : " +
            (exceptions.toMessage(error) || "problème technique")
        );
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    completed() {
      if (!this.label) return false;

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      if (!areTheSame(this.source.digitalName, trimString(this.digitalName))) {
        changed = true;
      }
      if (this.source.isPerson != this.isPerson) {
        changed = true;
      }

      return changed;
    },
  },
  mounted() {
    // Instanciation de la classe service
    this.userTypeService = new UsersTypeService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.userTypeId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>